import React from 'react';
import Post from '../Components/Post';
import {useEffect, useState} from "react";

const IndexPage = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(process.env.REACT_APP_SITE_URL+'/post').then(response => {
      response.json().then(posts => {
        setPosts(posts);
      });
    });
  },[]);
  return (
    <>
    {posts.length > 0 && posts.map(post => (
      <Post {...post} />
    ))}
    </>
  )
}

export default IndexPage